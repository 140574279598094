@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

[class*="slick-dots"] button::before
    font-size: 12px !important